import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    choice_show: "", //"post"or"search"or"chat"
    map_type: "show", //"show"or"post"
    //    post_search_type: { type: "public", page: 0 }, //"public"or"star"or"owner"
    posts_marker: [], //[obj,obj]
    /*    posts_marker: {
      fire: "",
      items: []
    }, //[obj,obj]*/
    list_type: "public", //"public"or"star"or"owner"
    posts_list: {
      show: false,
      items: [],
      showNextPage: false,
      page: 0,
      keyword: "",
    },
    events_list: {
      show: true,
      items: [],
      period: "before",
      keyword: "",
      //      showNextPage: false,
    },
    stars_list: {
      show: false,
      items: [],
      showNextPage: false,
      page: 0,
      keyword: "スター",
      keyword_id: "1",
      image_url: "",
      image_s3_key: "",
    },
    star_list_dialog: {
      show: false,
      items: [],
      star_id: "",
      event_id: "",
      post_id: "",
      other_owner: "",
    },
    public_stars_list: {
      show: false,
      status: "",
      account: {
        user_id: "",
        user_name: "",
        comment: "",
        header_image: "",
        thumbnail: "",
        star_list_keys: [],
        owner: "",
      },
      items: [],
      showNextPage: false,
      page: 0,
      keyword: "",
      keyword_id: "",
      keyword_lock: true,
    },
    profile_update: {
      show: false,
      account: {
        header_delete: false,
        new_header_image: null,
        thumbnail_delete: false,
        new_thumbnail_image: null,
        comment: "",
      },
    },
    chat_marker: [],
    search_keyword: {
      type: null,
      keyword: "",
    }, //{type:"keyword"or"firstURI",keyword:""}
    search_api: {},
    maerker_reload: "", //uuid
    get_current: "", //uuid
    post_image_point: "", //uuid
    search_geocode: {
      fire: "",
      geocode: "",
      zoom: "",
    },
    show_qr_code: false,
    show_login_dialog: {
      top: false,
      star: false,
      event: false,
    },
    post_uri: {},
    event_uri: {
      fire: "",
      uri: "",
    },
    star_uri: {
      fire: "",
      uri: "",
    },
    post_point: {},
    qr_code: {}, //{fire:"uuid",url:""}
    account: null,
    account_info: {
      user_id: "",
      user_name: "",
      header_img_key: "",
      thumbnail_key: "",
      comment: "",
      star_list_keys: [],
    },
    map_setting: {
      bounds: {},
      zoom: "",
      old_zoom: "",
      center: {},
      auto_search: true,
      marker_setting: "", //""or"
    },
    user_setting: {
      free_id: null,
    },
    making_post: {
      point: {},
      image: null,
      image_point: null,
    },
  },
  mutations: {
    setUser(state, account) {
      state.account = account;
    },
    setAccount(state, account) {
      state.account_info.user_id = account.user_id;
      state.account_info.user_name = account.user_name;
      state.account_info.header_img_key =
        account.header_img_key == null ? "" : account.header_img_key;
      state.account_info.thumbnail_key =
        account.thumbnail_key == null ? "" : account.thumbnail_key;
      state.account_info.comment = account.comment;
      state.account_info.star_list_keys = [];
      if (account.star_list_keys != "") {
        const andSplitString = account.star_list_keys.split("'");
        andSplitString.forEach((el) => {
          const splitKeys = el.split(":");
          const escapeString = splitKeys[1].replace(
            /&amp;|&#x27;|&#x60;|&quot;|&lt;|&gt;|&#058;/g,
            function (match) {
              return {
                "&amp;": "&",
                "&#x27;": "'",
                "&#x60;": "`",
                "&quot;": '"',
                "&lt;": "<",
                "&gt;": ">",
                "&#058;": ":",
              }[match];
            }
          );
          state.account_info.star_list_keys.push({
            id: splitKeys[0],
            name: escapeString,
            public: splitKeys[2],
            default: splitKeys[3],
          });
          if (splitKeys[3] == "0") {
            state.stars_list.keyword = escapeString;
            state.stars_list.keyword_id = splitKeys[0];
          }
        });
      }
    },
    maerkerReload(state, fire) {
      state.maerker_reload = fire;
    },
  },
  actions: {},
  modules: {},
  getters: {
    choice_show(state) {
      return state.choice_show;
    },
    map_type(state) {
      return state.map_type;
    },
    posts_marker(state) {
      return state.posts_marker;
    },
    chat_marker(state) {
      return state.chat_marker;
    },
    search_keyword(state) {
      return state.search_keyword;
    },
    search_api(state) {
      return state.search_api;
    },
    maerker_reload(state) {
      return state.maerker_reload;
    },
    get_current(state) {
      return state.get_current;
    },
    post_image_point(state) {
      return state.post_image_point;
    },
    search_geocode(state) {
      return state.search_geocode;
    },
    post_uri(state) {
      return state.post_uri;
    },
    event_uri(state) {
      return state.event_uri;
    },
    star_uri(state) {
      return state.star_uri;
    },
    post_point(state) {
      return state.post_point;
    },
    qr_code(state) {
      return state.qr_code;
    },
    account(state) {
      return state.account;
    },
  },
});
