import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import Amplify, * as AmplifyModules from "aws-amplify";
import { AuthModeStrategyType } from "aws-amplify";
import { AmplifyPlugin } from "aws-amplify-vue";
import awsconfig from "./aws-exports";
/*awsconfig.aws_appsync_authenticationType = "API_KEY";
Amplify.configure(awsconfig);*/
/*Amplify.configure({
  ...awsconfig,
  aws_appsync_authenticationType: "API_KEY",
});*/
Amplify.configure({
  ...awsconfig,
  DataStore: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
  },
});

/*
import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import VueApollo from "vue-apollo";

const config = {
  url: awsconfig.aws_appsync_graphqlEndpoint,
  region: awsconfig.aws_appsync_region,
  auth: {
    type: AUTH_TYPE.API_KEY, // or type: awsconfig.aws_appsync_authenticationType,
    apiKey: awsconfig.aws_appsync_apiKey,
  },
  disableOffline: true
};
const options = {
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network"
    }
  }
}
const client = new AWSAppSyncClient(config, options);

const apolloProvider = new VueApollo({ defaultClient: client });

Vue.use(VueApollo);*/

Vue.use(AmplifyPlugin, AmplifyModules);

Vue.config.productionTip = false;

/*import VueGtag from "vue-gtag";
 Vue.use(VueGtag, {
   config: { id: process.env.VUE_APP_GOOGLE_GTAG_ID }
 });
 console.log(process.env.VUE_APP_GOOGLE_GTAG_ID)*/
/*import VueGtm from "vue-gtm";
Vue.use(VueGtm, {
  id: process.env.VUE_APP_GOOGLE_GTM_ID,
});*/
/*import VueAnalytics from "vue-analytics";
Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID
});*/

import uuid from "vue-uuid";
Vue.use(uuid);

import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

import axios from "axios";
Vue.prototype.$axios = axios;

/*import Swiper from "swiper/swiper-bundle.esm";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
Vue.use(getAwesomeSwiper(Swiper));
import "swiper/swiper-bundle.css";*/

import Notifications from "vue-notification";
Vue.use(Notifications);

//import VeeValidate from 'vee-validate';
//Vue.use(VeeValidate);

import { localize } from "vee-validate";
import ja from "vee-validate/dist/locale/ja.json";
localize("ja", ja);

import gv from "@/mixins/globalValiables";
Vue.mixin(gv);

new Vue({
  router,
  store,
  vuetify,

  //  provide: apolloProvider.provide(),
  render: (h) => h(App),
}).$mount("#app");

/*
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
*/
