import { DataStore, Predicates } from "@aws-amplify/datastore";
import { Account, Star, Post } from "../models";

import { API } from "aws-amplify";
import * as queries from "../graphql/queries";

import Analytics from "@aws-amplify/analytics";

export default {
  beforeCreate() {
    this.post_point = require("../assets/icommu_light.png");

    DataStore.start();
  },
  created() {},
  data() {
    return {
      linkCopy: "リンクのコピー",
      QRCode: "QRコード",
      postPointdefaultClassName: "defaultPointImageOutside",
      postPointImageClassName: "pointImageOutside",
    };
  },
  methods: {
    globalMetaTagInput(obj) {
      const description = obj.description.replace(/<br\/>|<br>/g, "");

      document.querySelector("title").textContent = obj.title;
      document
        .querySelector('meta[property="og:title"]')
        .setAttribute("content", obj.title);
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", description);
      document
        .querySelector('meta[property="og:description"]')
        .setAttribute("content", description);
      document
        .querySelector('meta[property="og:url"]')
        .setAttribute("content", obj.url);
      document
        .querySelector('meta[property="og:image"]')
        .setAttribute("content", obj.image);
      document
        .querySelector('meta[property="og:type"]')
        .setAttribute("content", obj.type);
    },
    sanitaizeNewlineText(string) {
      let escape = string.replace(/[&'`"<>:]/g, function (match) {
        return {
          "&": "&amp;",
          "'": "&#x27;",
          "`": "&#x60;",
          '"': "&quot;",
          "<": "&lt;",
          ">": "&gt;",
          ":": "&#058;",
        }[match];
      });
      return escape.replace(/\n/g, "<br>");
    },
    gloabalEscapeString(string) {
      return string.replace(
        /&amp;|&#x27;|&#x60;|&quot;|&lt;|&gt;|&#058;/g,
        function (match) {
          return {
            "&amp;": "&",
            "&#x27;": "'",
            "&#x60;": "`",
            "&quot;": '"',
            "&lt;": "<",
            "&gt;": ">",
            "&#058;": ":",
          }[match];
        }
      );
    },
    createElementDiv(className) {
      var div = document.createElement("div");
      div.className = className;
      return div;
    },
    createElementImg(src) {
      var image = document.createElement("img");
      image.setAttribute("src", src);
      return image;
    },
    geojsonFormToGraphql(array) {
      var geojsonForm = [];

      for (var value of array) {
        if (value.type == "event") {
          /*          geojsonForm.push({
            location_latlng: [value.lng, value.lat],
            event_id: value.id,
            event_url: btoa(value.id),
          });*/
          geojsonForm.push(this.globalEventGeojsonForm(value));
        } else {
          geojsonForm.push({
            location_latlng: [value.lng, value.lat],
            post_id: value.id,
            post_img: value.image_key,
            post_url: btoa(value.id),
          });
        }
      }
      this.$store.state.posts_marker = geojsonForm;
    },
    globalEventGeojsonForm(obj) {
      return {
        location_latlng: [obj.lng, obj.lat],
        event_id: obj.id,
        event_image_url: obj.image_url ? obj.image_url : "",
        event_image_key: obj.image_s3_key ? obj.image_s3_key : "",
        event_url: btoa(obj.id),
      };
    },
    /*    globalReloadPostsMarker() {
      const savedPostsMarker = this.$store.state.posts_marker;
      this.$store.state.posts_marker = [];
      this.$store.state.posts_marker = savedPostsMarker;
    },*/
    keywordObject(string) {
      return {
        type: "keyword",
        keyword: string,
      };
    },
    globalUrlReplace(string) {
      var elementDiv = this.createElementDiv("");
      elementDiv.innerHTML = string.replace(
        /((http|https|ftp):\/\/[\w?=&./-;#~%-]+(?![\w\s?&./;#~%"=-]*>))/gi,
        '<a href="$1">$1</a>'
      );

      let linkArray = elementDiv.getElementsByTagName("a");
      let stringObj = elementDiv.outerHTML;
      if (linkArray.length) {
        for (let link of linkArray) {
          var splitStrings = stringObj.split(link.outerHTML);
          var linkOrigin = link.origin.replace(/^(http|https|ftp):\/\//i, "");
          var linkPathname = link.pathname.split("/");
          var stringJoin = "";
          splitStrings.forEach((string, index) => {
            if (splitStrings.length - 1 != index) {
              stringJoin =
                stringJoin +
                string +
                "<a href=" +
                link.href +
                ' target="_blank" rel="noopener noreferrer">' +
                linkOrigin +
                "/" +
                linkPathname[1] +
                "</a>";
            } else {
              stringJoin = stringJoin + string;
            }
          });
          stringObj = stringJoin;
        }
      }
      return stringObj;
    },
    globalHashtagReplace(string) {
      var match_st = string.match(
        /[#＃][Ａ-Ｚａ-ｚA-Za-z一-鿆0-9０-９ぁ-ヶｦ-ﾟー.&＆_-]+/gm
      );
      if (match_st) {
        match_st.forEach((hashTag) => {
          var hashSplitStrings = string.split(hashTag);
          var contentString = "";
          hashSplitStrings.forEach((string, index) => {
            if (
              string.match(
                /((http|https|ftp):\/\/[\w?=&./-;#~%-]+(?![\w\s?&./;#~%"=-]*>))$/i
              )
            ) {
              if (hashSplitStrings.length - 1 != index) {
                contentString = contentString + string + hashTag;
              } else {
                contentString = contentString + string;
              }
            } else {
              if (hashSplitStrings.length - 1 != index) {
                var escapeHasyTag = hashTag.replace(/#/i, "&#35;");
                escapeHasyTag = escapeHasyTag.replace(/＃/i, "&#65283;");
                contentString =
                  contentString +
                  string +
                  "<span class='hashtag'>" +
                  escapeHasyTag +
                  "</span>";
              } else {
                contentString = contentString + string;
              }
            }
          });
          string = contentString;
        });
      }
      return string;
    },
    globalResizeImage(obj) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (obj.image.width < obj.maxWidth && obj.image.height < obj.maxHeight) {
        [canvas.width, canvas.height] = [obj.image.width, obj.image.height];
        ctx.drawImage(obj.image, 0, 0);
        return canvas.toDataURL("image/png");
      }

      let dstWidth;
      let dstHeight;

      if (obj.image.width > obj.maxWidth && obj.image.height > obj.maxHeight) {
        if (obj.image.width === obj.image.height) {
          dstHeight = obj.maxWidth;
          dstWidth = obj.maxWidth;
        } else if (obj.image.width > obj.image.height) {
          const ratio = obj.image.height / obj.image.width;
          dstHeight = obj.maxWidth * ratio;
          dstWidth = obj.maxWidth;
          if (dstHeight > obj.maxHeight) {
            dstHeight = obj.maxHeight;
            dstWidth = obj.maxHeight / ratio;
          }
        } else {
          const ratio = obj.image.width / obj.image.height;
          dstHeight = obj.maxHeight;
          dstWidth = obj.maxHeight * ratio;
          if (dstWidth > obj.maxWidth) {
            dstHeight = obj.maxWidth / ratio;
            dstWidth = obj.maxWidth;
          }
        }
      } else if (
        obj.image.width > obj.maxWidth ||
        obj.image.height > obj.maxHeight
      ) {
        if (obj.image.width > obj.maxWidth) {
          const ratio = obj.image.height / obj.image.width;
          dstHeight = obj.maxWidth * ratio;
          dstWidth = obj.maxWidth;
          if (dstHeight > obj.maxHeight) {
            dstHeight = obj.maxHeight;
            dstWidth = obj.maxHeight / ratio;
          }
        } else {
          const ratio = obj.image.width / obj.image.height;
          dstHeight = obj.maxHeight;
          dstWidth = obj.maxHeight * ratio;
          if (dstWidth > obj.maxWidth) {
            dstHeight = obj.maxWidth / ratio;
            dstWidth = obj.maxWidth;
          }
        }
      } else {
        dstHeight = obj.image.height;
        dstWidth = obj.image.width;
      }

      canvas.width = dstWidth;
      canvas.height = dstHeight;

      ctx.drawImage(
        obj.image,
        0,
        0,
        obj.image.width,
        obj.image.height,
        0,
        0,
        dstWidth,
        dstHeight
      );

      return canvas.toDataURL("image/png");
    },
    globalBase64ToBlob(base64) {
      const bin = atob(base64.replace(/^.*,/, ""));
      const buffer = new Uint8Array(bin.length);
      for (let i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
      }
      return new Blob([buffer.buffer], {
        type: this.updataFileType || "image/jpeg",
      });
    },
    globalAssignPostsList(post) {
      return {
        id: post.id,
        content: post.content.replace(/<br\/>|<br>/g, ""),
        image_key: post.image_key,
        lat: post.lat,
        lng: post.lng,
        owner: post.owner,
        user_key: post.user_key,
      };
    },
    globalAssignToPost(obj) {
      return {
        type: "post",
        id: obj.post.id,
        star_id: obj.star.id,
        content: obj.post.content.replace(/<br\/>|<br>/g, ""),
        image_key: obj.post.image_key,
        lat: obj.post.lat,
        lng: obj.post.lng,
        owner: obj.post.owner,
        user_key: obj.post.user_key,
      };
    },
    globalAssignToEvent(obj) {
      return {
        type: "event",
        id: obj.event.id,
        star_id: obj.star.id,
        title: obj.event.title,
        facility: obj.event.facility_name,
        image_url: obj.event.image_url ? obj.event.image_url : "",
        image_s3_key: obj.event.image_s3_key ? obj.event.image_s3_key : "",
        event_url: btoa(obj.event.id),
        lat: obj.event.lat,
        lng: obj.event.lng,
        address: obj.event.address,
      };
    },
    stateQRCodeInput(urlString) {
      this.$store.state.qr_code = {
        fire: this.$uuid.v4(),
        url: urlString,
      };
    },
    async uniqueUserId(userId) {
      let returnMessage = {
        type: "success",
        text: "ユーザーIDは利用できます",
        time: 1000,
      };
      const pkUserId = "userId:" + userId;
      const apiAccount = await API.graphql({
        query: queries.getAccount,
        variables: { id: pkUserId },
      });
      if (apiAccount.data.getAccount) {
        if (
          apiAccount.data.getAccount._deleted == null &&
          apiAccount.data.getAccount.owner ==
            this.$store.state.account.attributes.sub
        ) {
          returnMessage = {
            type: "warn",
            text: "利用中のユーザーIDです",
            time: 1000,
          };
        } else if (apiAccount.data.getAccount._deleted == 1) {
          returnMessage = {
            type: "error",
            text: "削除中のユーザーIDで利用できません",
            time: 2000,
          };
        } else {
          returnMessage = {
            type: "error",
            text: "使用されているIDです",
            time: 2000,
          };
        }
      }
      return returnMessage;
    },
    async graphqlStarConfirm(filter) {
      if (this.$store.state.account !== null) {
        const graphqlStar = await API.graphql({
          query: queries.listStars,
          variables: { filter: filter },
          authMode: "API_KEY",
        });
        this.refreshStarListDetail();
        if (graphqlStar.data.listStars.items.length) {
          graphqlStar.data.listStars.items.forEach((element) => {
            if (element._deleted == null) {
              this.globalStarListDetail(element);
            }
          });
        }
      }
    },
    globalStarListDetail(star) {
      if (star) {
        this.$store.state.star_list_dialog.star_id = star.id;
        this.$store.state.star_list_dialog.event_id = star.event_id;
        this.$store.state.star_list_dialog.post_id = star.post_id;
        this.$store.state.star_list_dialog.other_owner = star.other_owner;
        let selectKeySplit = star.select_key.split("'");
        selectKeySplit = selectKeySplit.filter((n) => n);
        let starListDetail = [];
        this.$store.state.account_info.star_list_keys.forEach((starList) => {
          starList.checked = false;
          selectKeySplit.forEach((key) => {
            if (key == starList.id) {
              starList.checked = true;
            }
          });
          starListDetail.push(starList);
        });
        this.$store.state.star_list_dialog.items = starListDetail;
      } else {
        this.refreshStarListDetail();
      }
    },
    refreshStarListDetail() {
      this.$store.state.star_list_dialog.star_id = "";
      this.$store.state.star_list_dialog.event_id = "";
      this.$store.state.star_list_dialog.post_id = "";
      this.$store.state.star_list_dialog.other_owner = "";
      this.$store.state.star_list_dialog.items = [];
    },
    
    async globalDatastoreCheck() {
      return await DataStore.query(Post, Predicates.ALL, {
        limit: 1,
      });
    },
    async globalAccountConfirm() {
      let accountBool = false;
      const account = await DataStore.query(
        Account,
        this.$store.state.account.attributes.sub
      );
      if (account) {
        accountBool = true;
        this.globalAccountInfoImport(account);
      }

      return accountBool;
    },
    globalPublicStarsListImport(account) {
      this.$store.state.public_stars_list.account.header_image =
        account.header_img_key
          ? process.env.VUE_APP_POST_IMAGE_DOMAIN + account.header_img_key
          : "";
      this.$store.state.public_stars_list.account.thumbnail =
        account.thumbnail_key
          ? process.env.VUE_APP_POST_IMAGE_DOMAIN + account.thumbnail_key
          : "";
      this.$store.state.public_stars_list.account.user_name = account.user_name;
      this.$store.state.public_stars_list.account.user_id = account.user_id;
      this.$store.state.public_stars_list.account.comment = account.comment;
      this.$store.state.public_stars_list.account.owner = account.owner;
      this.$store.state.public_stars_list.account.star_list_keys = [];
      this.$store.state.public_stars_list.keyword = "";
      this.$store.state.public_stars_list.keyword_id = "";

      const andSplitString = account.star_list_keys.split("'");

      andSplitString.forEach((el) => {
        const splitKeys = el.split(":");
        const escapeString = this.gloabalEscapeString(splitKeys[1]);

        if (this.$route.query["ke"] && this.$route.query["wo"]) {
          if (
            splitKeys[2] == "true" ||
            this.$route.query["ke"] == splitKeys[0]
          ) {
            this.$store.state.public_stars_list.account.star_list_keys.push({
              id: splitKeys[0],
              name: escapeString,
              public: splitKeys[2],
              default: splitKeys[3],
            });
          }
          if (this.$route.query["ke"] == splitKeys[0]) {
            this.$store.state.public_stars_list.keyword = escapeString;
            this.$store.state.public_stars_list.keyword_id = splitKeys[0];
            this.$store.state.public_stars_list.keyword_lock =
              splitKeys[2] == "true" ? false : true;
          }
        } else {
          if (splitKeys[2] == "true") {
            this.$store.state.public_stars_list.account.star_list_keys.push({
              id: splitKeys[0],
              name: escapeString,
              public: splitKeys[2],
              default: splitKeys[3],
            });
            if (splitKeys[3] == "0") {
              this.$store.state.public_stars_list.keyword = escapeString;
              this.$store.state.public_stars_list.keyword_id = splitKeys[0];
              this.$store.state.public_stars_list.keyword_lock = false;
            }
          }
        }
      });

      if (
        this.$store.state.public_stars_list.account.star_list_keys.length &&
        this.$store.state.public_stars_list.keyword == "" &&
        this.$store.state.public_stars_list.keyword_id == ""
      ) {
        this.$store.state.public_stars_list.keyword =
          this.$store.state.public_stars_list.account.star_list_keys[0].name;
        this.$store.state.public_stars_list.keyword_id =
          this.$store.state.public_stars_list.account.star_list_keys[0].id;
        this.$store.state.public_stars_list.keyword_lock =
          this.$store.state.public_stars_list.account.star_list_keys[0]
            .public == "true"
            ? false
            : true;
      }
    },
    globalAccountInfoImport(account) {
      this.$store.state.account_info.user_id = account.user_id;
      this.$store.state.account_info.user_name = account.user_name;

      this.$store.state.account_info.header_img_key =
        account.header_img_key == null ? "" : account.header_img_key;
      this.$store.state.account_info.thumbnail_key =
        account.thumbnail_key == null ? "" : account.thumbnail_key;
      this.$store.state.account_info.comment = account.comment;

      this.$store.state.account_info.star_list_keys = [];
      const andSplitString = account.star_list_keys.split("'");

      andSplitString.forEach((el) => {
        const splitKeys = el.split(":");
        const escapeString = this.gloabalEscapeString(splitKeys[1]);
        this.$store.state.account_info.star_list_keys.push({
          id: splitKeys[0],
          name: escapeString,
          public: splitKeys[2],
          default: splitKeys[3],
        });
        if (
          splitKeys[3] == "0" &&
          this.$store.state.stars_list.keyword == "" &&
          this.$store.state.stars_list.keyword_id == ""
        ) {
          this.$store.state.stars_list.keyword = escapeString;
          this.$store.state.stars_list.keyword_id = splitKeys[0];
        }
      });
    },
    async globalAddStar(starData) {
      let defaultId = 1;

      this.$store.state.account_info.star_list_keys.forEach((star) => {
        if (star.default == "0") {
          defaultId = star.id;
        }
      });
      try {
        starData.select_key = "'" + defaultId + "'";

        const star = await DataStore.save(new Star(starData)).catch((err) => {
          Analytics.record({
            name: "starDataStoreError",
            attributes: {
              error: err,
            },
          });
        });
        this.globalStarListDetail(star);
      } catch (err) {
        this.$notify({
          group: "starMessage",
          title: "<h4>スター</h4>",
          text: "スターの追加に失敗しています。しばらくしてから再度お試し下さい。",
          type: "error",
          duration: 2000,
        });
      }
    },
    async globalRemoveStar(star) {
      if (star) {
        this.$store.state.star_list_dialog.star_id = "";
        DataStore.delete(star);
        if (this.$store.state.list_type == "star") {
          let showListItems = [];

          this.$store.state.stars_list.items.forEach((item) => {
            if (item.star_id != star.id) {
              showListItems.push(item);
            }
          });

          this.$store.state.stars_list.items = showListItems;

          this.geojsonFormToGraphql(this.$store.state.stars_list.items);
        }
      }
    },
    async globalStarsDelete(post_id) {
      const stars = await DataStore.query(Star, (c) =>
        c.post_id("eq", post_id)
      );
      if (stars.length) {
        for (var star of stars) {
          await DataStore.delete(star);
        }
      }
    },
    globalShowDate(date) {
      const eventDate = new Date(date);
      return (
        eventDate.getFullYear() +
        " " +
        (Number(eventDate.getMonth()) + 1) +
        "/" +
        eventDate.getDate()
      );
    },
    awsSESMyTemplate(obj) {
      const aws = require("aws-sdk");
      const configJson = {
        accessKeyId: process.env.VUE_APP_AWS_KEY_ID,
        secretAccessKey: process.env.VUE_APP_AWS_SECRET_KEY,
        region: "us-west-2",
      };

      aws.config.update(configJson);
      const ses = new aws.SES();

      let mail_template = {
        Destination: {
          ToAddresses: obj.to, // 送信先アドレス(配列で複数設定可能)
        },
        Source: "info@icommu.co.jp", // 送信元アドレス
        Template: "MyTemplate", // 使用するテンプレート名
        TemplateData:
          '{"title":"' +
          obj.title +
          '","html":"' +
          obj.html +
          '","text":"' +
          obj.text +
          '"}', // テンプレート内に入れる値
      };

      ses.sendTemplatedEmail(mail_template, function (err, data) {
        if (data) {
          console.log(data);
        }
        if (err) {
          console.log(err);
        }
      });
    },
  },
  computed: {},
};
