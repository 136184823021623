import Vue from "vue";
import VueRouter from "vue-router";
//import Light from "../views/IcommuLight.vue";

//import store from '../store/index.js';

Vue.use(VueRouter);

const metaDesc =
  "iCommu (アイコミュ)は、地図上でイベントや旅の思い出をシェアするサイトです。匿名での投稿やチャットを使って旅先や周辺の情報収集にご利用ください。現地の写真を投稿してお気に入りスポットを共有しましょう。";

import { Hub } from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import store from "../store";
import { DataStore } from "@aws-amplify/datastore";

import { Account } from "../models";

Hub.listen("auth", async (data) => {
  if (data.payload.event === "signOut") {
    store.commit("setUser", null);

    const listener = Hub.listen("datastore", async (hubData) => {
      const { event } = hubData.payload;
      if (event === "ready") {
        store.commit("maerkerReload", "fire2");
      }
    });
    setTimeout(() => {
      listener();
    }, 10000);
    DataStore.clear();
    DataStore.start();
    setTimeout(() => {
      store.commit("maerkerReload", "fire1");
    }, 100);

    store.commit("setAccount", {
      user_id: "",
      user_name: "",
      header_img_key: "",
      thumbnail_key: "",
      star_list_keys: "",
    });
  } else if (data.payload.event === "signIn") {
    //      DataStore.clear();
  }
});
Auth.currentAuthenticatedUser()
  .then((data) => {
    if (data && data.signInUserSession) {
      store.commit("setUser", data);

      const listener = Hub.listen("datastore", async (hubData) => {
        const { event } = hubData.payload;
        if (event === "ready") {
          const account = await DataStore.query(Account, data.attributes.sub);
          if (account) {
            store.commit("setAccount", account);
          }
        }
      });
      setTimeout(() => {
        listener();
      }, 10000);
    } else {
      store.commit("setUser", null);
      DataStore.clear();
      DataStore.start();
    }
  })
  .catch(() => {
    store.commit("setUser", null);
  });

const routes = [
  //  { path: "/", redirect: "/light" },
  { path: "/ia/mobile", redirect: "/" },
  {
    path: "/",
    name: "Light",
    //    component: Light,
    component: () =>
      import(/* webpackChunkName: "light" */ "../views/IcommuLight.vue"),
    meta: { title: "iCommu", desc: metaDesc },
  },
  {
    path: "/post/:id",
    name: "postDetail",
    component: () =>
      import(/* webpackChunkName: "postDetail" */ "../views/PostDetail.vue"),
    meta: { title: "iCommu", desc: metaDesc },
  },
  {
    path: "/event/:id",
    name: "eventDetail",
    component: () =>
      import(/* webpackChunkName: "eventDetail" */ "../views/EventDetail.vue"),
    meta: { title: "iCommu", desc: metaDesc },
  },
  {
    path: "/:id/star",
    name: "publicStars",
    component: () =>
      import(
        /* webpackChunkName: "eventDetail" */ "../views/PublicStarsList.vue"
      ),
    meta: { title: "iCommu", desc: metaDesc },
  },
  {
    path: "/404",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "notFound" */ "../views/404NotFound.vue"),
    meta: { title: "404", desc: "iCommuに存在しないURLです。" },
  },
  {
    path: "*",
    name: "notFound",
    redirect: "/404",
  },
  {
    path: "/account/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "../account/Login.vue"),
    meta: { title: "iCommu", desc: metaDesc },
    //redirect: "/404",
  },
  {
    path: "/account/password/reset",
    name: "PasswordReset",
    component: () =>
      import(
        /* webpackChunkName: "PasswordReset" */ "../account/ForgotPassword.vue"
      ),
    meta: { title: "iCommu", desc: metaDesc },
    //redirect: "/404",
  },
  {
    path: "/account/signup",
    name: "Signup",
    component: () =>
      import(/* webpackChunkName: "Signup" */ "../account/Signup.vue"),
    meta: { title: "iCommu", desc: metaDesc },
    //redirect: "/404",
  },
  {
    path: "/account/signup/config",
    name: "SignupConfig",
    component: () =>
      import(
        /* webpackChunkName: "SignupConfig" */ "../account/ConfigSignup.vue"
      ),
    meta: { title: "iCommu", desc: metaDesc },
    //redirect: "/404",
  },
  {
    path: "/account/register",
    name: "NewRegistration",
    component: () =>
      import(
        /* webpackChunkName: "NewRegistration" */ "../account/NewAccount.vue"
      ),
    meta: { title: "iCommu", desc: metaDesc, requiresAuth: true },
    //redirect: "/404",
  },
  {
    path: "/account/management",
    name: "AccountManagemenet",
    component: () =>
      import(
        /* webpackChunkName: "AccountManagemenet" */ "../account/Management.vue"
      ),
    meta: { title: "iCommu", desc: metaDesc, requiresAuth: true },
    //redirect: "/404",
  },
  {
    path: "/account/profile",
    name: "AccountPlofile",
    component: () =>
      import(
        /* webpackChunkName: "AccountPlofile" */ "../account/EditProfile.vue"
      ),
    meta: { title: "iCommu", desc: metaDesc, requiresAuth: true },
    //redirect: "/404",
  },
  {
    path: "/setting/about",
    name: "SettingAbout",
    component: () =>
      import(
        /* webpackChunkName: "SettingAbout" */ "../views/SettingAbout.vue"
      ),
    meta: { title: "iCommu", desc: metaDesc },
  },
  {
    path: "/setting/terms",
    name: "SettingTerms",
    component: () =>
      import(
        /* webpackChunkName: "SettingTerms" */ "../views/SettingTerms.vue"
      ),
    meta: { title: "iCommu", desc: metaDesc },
  },
  {
    path: "/setting/help",
    name: "SettingHelp",
    component: () =>
      import(/* webpackChunkName: "SettingTerms" */ "../views/SettingHelp.vue"),
    meta: { title: "iCommu", desc: metaDesc },
  },
  {
    path: "/setting/privacy",
    name: "SettingPrivacy",
    component: () =>
      import(
        /* webpackChunkName: "SettingTerms" */ "../views/SettingPrivacy.vue"
      ),
    meta: { title: "iCommu", desc: metaDesc },
  },
  {
    path: "/setting/icommu",
    name: "SettingIcommu",
    component: () =>
      import(
        /* webpackChunkName: "SettingTerms" */ "../views/SettingIcommu.vue"
      ),
    meta: { title: "iCommu", desc: metaDesc },
  },
  {
    path: "/setting/company",
    name: "SettingCompany",
    component: () =>
      import(
        /* webpackChunkName: "SettingTerms" */ "../views/SettingCompany.vue"
      ),
    meta: { title: "iCommu", desc: metaDesc },
  },
];

const router = new VueRouter({
  mode: "history",
  //  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  /*  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    store.state.account === null
  ) {
    next({ path: "/account/login" });
  } else {
    next();
  }*/

  setTimeout(() => {
    if (
      to.matched.some((record) => record.meta.requiresAuth) &&
      store.state.account === null
    ) {
      next({ path: "/account/login" });
    } else {
      next();
    }
  }, 100);
});

export default router;
