// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Chat, Post, Account, Star, Userkeypostblock, Facility, Facilityevent, Imageconnect } = initSchema(schema);

export {
  Chat,
  Post,
  Account,
  Star,
  Userkeypostblock,
  Facility,
  Facilityevent,
  Imageconnect
};