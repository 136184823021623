/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getChat = /* GraphQL */ `
  query GetChat($id: ID!) {
    getChat(id: $id) {
      id
      user_type
      user_key
      content
      lat
      lng
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listChats = /* GraphQL */ `
  query ListChats(
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_type
        user_key
        content
        lat
        lng
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncChats = /* GraphQL */ `
  query SyncChats(
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChats(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user_type
        user_key
        content
        lat
        lng
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      user_type
      user_key
      image_key
      content
      lat
      lng
      createdAt
      updatedAt
      owner
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_type
        user_key
        image_key
        content
        lat
        lng
        createdAt
        updatedAt
        owner
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPosts = /* GraphQL */ `
  query SyncPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPosts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user_type
        user_key
        image_key
        content
        lat
        lng
        createdAt
        updatedAt
        owner
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      user_id
      user_name
      header_img_key
      thumbnail_key
      comment
      star_list_keys
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        user_name
        header_img_key
        thumbnail_key
        comment
        star_list_keys
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAccounts = /* GraphQL */ `
  query SyncAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user_id
        user_name
        header_img_key
        thumbnail_key
        comment
        star_list_keys
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getStar = /* GraphQL */ `
  query GetStar($id: ID!) {
    getStar(id: $id) {
      id
      post_id
      event_id
      other_owner
      select_key
      createdAt
      owner
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listStars = /* GraphQL */ `
  query ListStars(
    $filter: ModelStarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStars(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        post_id
        event_id
        other_owner
        select_key
        createdAt
        owner
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncStars = /* GraphQL */ `
  query SyncStars(
    $filter: ModelStarFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStars(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        post_id
        event_id
        other_owner
        select_key
        createdAt
        owner
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserkeypostblock = /* GraphQL */ `
  query GetUserkeypostblock($id: ID!) {
    getUserkeypostblock(id: $id) {
      id
      user_key
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listUserkeypostblocks = /* GraphQL */ `
  query ListUserkeypostblocks(
    $filter: ModelUserkeypostblockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserkeypostblocks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_key
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserkeypostblocks = /* GraphQL */ `
  query SyncUserkeypostblocks(
    $filter: ModelUserkeypostblockFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserkeypostblocks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user_key
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getFacility = /* GraphQL */ `
  query GetFacility($id: ID!) {
    getFacility(id: $id) {
      id
      tel
      address
      name
      lat
      lng
      link_url
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFacilities = /* GraphQL */ `
  query ListFacilities(
    $filter: ModelFacilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFacilities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tel
        address
        name
        lat
        lng
        link_url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFacilities = /* GraphQL */ `
  query SyncFacilities(
    $filter: ModelFacilityFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFacilities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        tel
        address
        name
        lat
        lng
        link_url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFacilityevent = /* GraphQL */ `
  query GetFacilityevent($id: ID!) {
    getFacilityevent(id: $id) {
      id
      facility_id
      facility_name
      announce_date
      start_date
      end_date
      address
      lat
      lng
      title
      content
      image_s3_key
      image_url
      link_url
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFacilityevents = /* GraphQL */ `
  query ListFacilityevents(
    $filter: ModelFacilityeventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFacilityevents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        facility_id
        facility_name
        announce_date
        start_date
        end_date
        address
        lat
        lng
        title
        content
        image_s3_key
        image_url
        link_url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFacilityevents = /* GraphQL */ `
  query SyncFacilityevents(
    $filter: ModelFacilityeventFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFacilityevents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        facility_id
        facility_name
        announce_date
        start_date
        end_date
        address
        lat
        lng
        title
        content
        image_s3_key
        image_url
        link_url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getImageconnect = /* GraphQL */ `
  query GetImageconnect($id: ID!) {
    getImageconnect(id: $id) {
      id
      facilityevent_id
      image_key
      caption
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listImageconnects = /* GraphQL */ `
  query ListImageconnects(
    $filter: ModelImageconnectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImageconnects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        facilityevent_id
        image_key
        caption
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncImageconnects = /* GraphQL */ `
  query SyncImageconnects(
    $filter: ModelImageconnectFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncImageconnects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        facilityevent_id
        image_key
        caption
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
