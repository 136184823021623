<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  components: {},
  mounted() {
    if (localStorage.getItem("free_user_id")) {
      const free_id = JSON.parse(localStorage.getItem("free_user_id"));
      this.$store.state.user_setting.free_id = free_id;
    } else {
      const free_id = this.$uuid.v4();
      localStorage.setItem("free_user_id", JSON.stringify(free_id));
      this.$store.state.user_setting.free_id = free_id;
    }

    const routeInstance = this.$route;
    this.createMeta(routeInstance);
  },
  watch: {
    $route(to) {
      this.createMeta(to);
    },
  },
  methods: {
    createMeta(routeInstance) {
      if (routeInstance.meta.title) {
        document.title = routeInstance.meta.title;
      } else {
        document.title = "iCommu";
      }

      if (routeInstance.meta.desc) {
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", routeInstance.meta.desc);
      } else {
        document
          .querySelector("meta[name='description']")
          .setAttribute(
            "content",
            "iCommuは地図上でイベントや旅の思い出をシェアするサイトです。旅先の情報収集にお使い下さい。"
          );
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  margin: 0px;
}
body::-webkit-scrollbar {
  width: 5px;
}
body::-webkit-scrollbar-thumb {
  background: rgba(99, 99, 99, 0.9);
  border-radius: 100px;
}
body::-webkit-scrollbar-track {
  background: rgba(196, 196, 196, 0.6);
}
.v-application {
  position: absolute !important;
  top: 0;
  background-color: transparent !important;
}
</style>
