/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:92108a0d-4e69-456a-90d1-4d671abc41b7",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_uazviNsoV",
    "aws_user_pools_web_client_id": "4sbvlsj7jhshnp0d5s10up2tuu",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://zmif4fvnljhxvh2vgb7yhm6njy.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-2wgonev2izbpxb5qt7mmvjarqm",
    "aws_user_files_s3_bucket": "icommu-light-images21915-prod",
    "aws_user_files_s3_bucket_region": "ap-northeast-1",
    "aws_mobile_analytics_app_id": "4d9d153af2f944579e01f05ebd12ffd0",
    "aws_mobile_analytics_app_region": "us-west-2"
};


export default awsmobile;
